import { breakpoints, varsHoken } from "@hoken/design-system/themes";
import { recipe } from "@vanilla-extract/recipes";

// There's a typescript issue with css properties which name has more than one word and which value is defined as string e.g. flexDirection: "column". Issue https://github.com/mui/material-ui/issues/16307#issuecomment-1149081580

export const variants = {
  gap: {
    none: {
      gap: 0,
    },
    xxsmall: {
      gap: varsHoken.space["xxsmall"],
    },
    xsmall: {
      gap: varsHoken.space["xsmall"],
    },
    small: {
      gap: varsHoken.space["small"],
    },
    medium: {
      gap: varsHoken.space["medium"],
    },
    large: {
      gap: varsHoken.space["large"],
    },
    xlarge: {
      gap: varsHoken.space["xlarge"],
    },
    xxlarge: {
      // To do: create specific space for xxlarge gap for mobile, tablet and desktop
      gap: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          gap: varsHoken.space["xxlarge"],
        },
      },
    },
  },
  justify: {
    start: {
      // Check typescript issue at the top
      justifyContent: "flex-start" as const,
    },
    end: {
      // Check typescript issue at the top
      justifyContent: "flex-end" as const,
    },
    center: {
      // Check typescript issue at the top
      justifyContent: "center" as const,
    },
    spaceBetween: {
      // Check typescript issue at the top
      justifyContent: "space-between" as const,
    },
    spaceAround: {
      // Check typescript issue at the top
      justifyContent: "space-around" as const,
    },
    spaceEvenly: {
      // Check typescript issue at the top
      justifyContent: "space-evenly" as const,
    },
  },
  align: {
    center: {
      // Check typescript issue at the top
      alignItems: "center" as const,
    },
    start: {
      // Check typescript issue at the top
      alignItems: "flex-start" as const,
    },
    end: {
      // Check typescript issue at the top
      alignItems: "flex-end" as const,
    },
  },
  direction: {
    column: {
      // Check typescript issue at the top
      flexDirection: "column" as const,
    },
    row: {
      // Check typescript issue at the top
      flexDirection: "row" as const,
    },
  },
  width: {
    fitContent: {
      width: "fit-content",
    },
    full: {
      width: "100%",
    },
    xxsmall: {
      width: "10%",
    },
    xsmall: {
      width: "20%",
    },
    small: {
      width: "30%",
    },
    medium: {
      width: "50%",
    },
    large: {
      width: "70%",
    },
    xlarge: {
      width: "80%",
    },
    xxlarge: {
      width: "90%",
    },
    xxxlarge: {
      width: "95%",
    },
  },
  maxWidth: {
    xsmall: {
      maxWidth: varsHoken.containerWidth["xsmall"],
    },
    small: {
      maxWidth: varsHoken.containerWidth["small"],
    },
    medium: {
      maxWidth: varsHoken.containerWidth["medium"],
    },
    large: {
      maxWidth: varsHoken.containerWidth["large"],
    },
    xlarge: {
      maxWidth: varsHoken.containerWidth["xlarge"],
    },
    xxlarge: {
      maxWidth: varsHoken.containerWidth["xxlarge"],
    },
  },
  marginLeft: {
    auto: {
      marginLeft: "auto",
    },
    xxsmall: {
      marginLeft: varsHoken.space["xxsmall"],
    },
    xsmall: {
      marginLeft: varsHoken.space["xsmall"],
    },
    small: {
      marginLeft: varsHoken.space["small"],
    },
    medium: {
      marginLeft: varsHoken.space["medium"],
    },
    large: {
      marginLeft: varsHoken.space["large"],
    },
    xlarge: {
      marginLeft: varsHoken.space["xlarge"],
    },
    none: {
      marginLeft: 0,
    },
  },
  marginRight: {
    auto: {
      marginRight: "auto",
    },
    xxsmall: {
      marginRight: varsHoken.space["xxsmall"],
    },
    xsmall: {
      marginRight: varsHoken.space["xsmall"],
    },
    small: {
      marginRight: varsHoken.space["small"],
    },
    medium: {
      marginRight: varsHoken.space["medium"],
    },
    large: {
      marginRight: varsHoken.space["large"],
    },
    xlarge: {
      marginRight: varsHoken.space["xlarge"],
    },
    none: {
      marginRight: 0,
    },
  },
  marginTop: {
    auto: {
      marginTop: "auto",
    },
    xxsmall: {
      marginTop: varsHoken.space["xxsmall"],
    },
    xsmall: {
      marginTop: varsHoken.space["xsmall"],
    },
    small: {
      marginTop: varsHoken.space["small"],
    },
    medium: {
      marginTop: varsHoken.space["medium"],
    },
    large: {
      marginTop: varsHoken.space["large"],
    },
    xlarge: {
      marginTop: varsHoken.space["xlarge"],
    },
    none: {
      marginTop: 0,
    },
  },
  marginBottom: {
    auto: {
      marginBottom: "auto",
    },
    xxsmall: {
      marginBottom: varsHoken.space["xxsmall"],
    },
    xsmall: {
      marginBottom: varsHoken.space["xsmall"],
    },
    small: {
      marginBottom: varsHoken.space["small"],
    },
    medium: {
      marginBottom: varsHoken.space["medium"],
    },
    large: {
      marginBottom: varsHoken.space["large"],
    },
    xlarge: {
      marginBottom: varsHoken.space["xlarge"],
    },
    none: {
      marginBottom: 0,
    },
  },
  margin: {
    auto: {
      margin: "auto",
    },
    none: {
      margin: "0",
    },
    xxsmall: {
      margin: varsHoken.space["xxsmall"],
    },
    xsmall: {
      margin: varsHoken.space["xsmall"],
    },
    small: {
      margin: varsHoken.space["small"],
    },
    medium: {
      margin: varsHoken.space["medium"],
    },
    large: {
      margin: varsHoken.space["large"],
    },
    xlarge: {
      margin: varsHoken.space["xlarge"],
    },
  },
  padding: {
    auto: {
      padding: "auto",
    },
    none: {
      padding: 0,
    },
    xxsmall: {
      padding: varsHoken.space["xxsmall"],
    },
    xsmall: {
      padding: varsHoken.space["xsmall"],
    },
    small: {
      padding: varsHoken.space["small"],
    },
    medium: {
      padding: varsHoken.space["medium"],
    },
    large: {
      padding: varsHoken.space["large"],
    },
    xlarge: {
      padding: varsHoken.space["xlarge"],
    },
    xxlarge: {
      padding: varsHoken.space["xxlarge"],
    },
    xxxlarge: {
      padding: varsHoken.space["xxxlarge"],
    },
  },
  paddingY: {
    none: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    xxsmall: {
      paddingTop: varsHoken.space["xxsmall"],
      paddingBottom: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingTop: varsHoken.space["xsmall"],
      paddingBottom: varsHoken.space["xsmall"],
    },
    small: {
      paddingTop: varsHoken.space["small"],
      paddingBottom: varsHoken.space["small"],
    },
    medium: {
      paddingTop: varsHoken.space["medium"],
      paddingBottom: varsHoken.space["medium"],
    },
    large: {
      paddingTop: varsHoken.space["large"],
      paddingBottom: varsHoken.space["large"],
    },
    xlarge: {
      paddingTop: varsHoken.space["xlarge"],
      paddingBottom: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingTop: varsHoken.space["xxlarge"],
      paddingBottom: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingTop: varsHoken.space["xxlarge"],
          paddingBottom: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingTop: varsHoken.space["xxlarge"],
      paddingBottom: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingTop: varsHoken.space["xxxlarge"],
          paddingBottom: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  paddingX: {
    none: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    xxsmall: {
      paddingLeft: varsHoken.space["xxsmall"],
      paddingRight: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingLeft: varsHoken.space["xsmall"],
      paddingRight: varsHoken.space["xsmall"],
    },
    small: {
      paddingLeft: varsHoken.space["small"],
      paddingRight: varsHoken.space["small"],
    },
    medium: {
      paddingLeft: varsHoken.space["medium"],
      paddingRight: varsHoken.space["medium"],
    },
    large: {
      paddingLeft: varsHoken.space["large"],
      paddingRight: varsHoken.space["large"],
    },
    xlarge: {
      paddingLeft: varsHoken.space["xlarge"],
      paddingRight: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingLeft: varsHoken.space["xlarge"],
      paddingRight: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingLeft: varsHoken.space["xxlarge"],
          paddingRight: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingLeft: varsHoken.space["xxlarge"],
      paddingRight: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingLeft: varsHoken.space["xxxlarge"],
          paddingRight: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  paddingTop: {
    none: {
      paddingTop: 0,
    },
    xxsmall: {
      paddingTop: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingTop: varsHoken.space["xsmall"],
    },
    small: {
      paddingTop: varsHoken.space["small"],
    },
    medium: {
      paddingTop: varsHoken.space["medium"],
    },
    large: {
      paddingTop: varsHoken.space["large"],
    },
    xlarge: {
      paddingTop: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingTop: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingTop: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingTop: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingTop: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  paddingBottom: {
    none: {
      paddingBottom: 0,
    },
    xxsmall: {
      paddingBottom: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingBottom: varsHoken.space["xsmall"],
    },
    small: {
      paddingBottom: varsHoken.space["small"],
    },
    medium: {
      paddingBottom: varsHoken.space["medium"],
    },
    large: {
      paddingBottom: varsHoken.space["large"],
    },
    xlarge: {
      paddingBottom: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingBottom: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingBottom: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingBottom: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingBottom: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  paddingLeft: {
    none: {
      paddingLeft: 0,
    },
    xxsmall: {
      paddingLeft: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingLeft: varsHoken.space["xsmall"],
    },
    small: {
      paddingLeft: varsHoken.space["small"],
    },
    medium: {
      paddingLeft: varsHoken.space["medium"],
    },
    large: {
      paddingLeft: varsHoken.space["large"],
    },
    xlarge: {
      paddingLeft: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingLeft: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingLeft: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingLeft: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingLeft: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  paddingRight: {
    none: {
      paddingRight: 0,
    },
    xxsmall: {
      paddingRight: varsHoken.space["xxsmall"],
    },
    xsmall: {
      paddingRight: varsHoken.space["xsmall"],
    },
    small: {
      paddingRight: varsHoken.space["small"],
    },
    medium: {
      paddingRight: varsHoken.space["medium"],
    },
    large: {
      paddingRight: varsHoken.space["large"],
    },
    xlarge: {
      paddingRight: varsHoken.space["xlarge"],
    },
    xxlarge: {
      paddingRight: varsHoken.space["xlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingRight: varsHoken.space["xxlarge"],
        },
      },
    },
    xxxlarge: {
      paddingRight: varsHoken.space["xxlarge"],
      "@media": {
        [breakpoints.sm]: {
          paddingRight: varsHoken.space["xxxlarge"],
        },
      },
    },
  },
  grid: {
    "2": {
      "@media": {
        [breakpoints.sm]: {
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
        },
        [breakpoints.md]: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
        },
      },
    },
    "3": {
      "@media": {
        [breakpoints.sm]: {
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
        },
        [breakpoints.md]: {
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        },
      },
    },
  },
};
export const autolayout = recipe({
  base: {
    display: "flex",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  variants: variants,
  defaultVariants: {
    gap: "medium",
    direction: "column",
  },
});
